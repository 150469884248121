import BookingForm from './components/BookingForm.vue'
import PaymentResult from './components/PaymentResult.vue'
export default [
    {
        path: '/', 
        component:BookingForm,
        meta: {
            title: "Home"
        }
    },
    {
        path: '/confirmation/:userid1/:userid2', component: PaymentResult
    }
]