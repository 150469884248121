<template>
    <div class="sticky-top">
        <v-row>
            <v-col cols="12" class="pb-0">
                Price
            </v-col>
            <v-col cols="12" class="pt-0">
                <h2>Rp {{ currency }}</h2>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="pb-0">
                Payment Methods
            </v-col>
            <v-col cols="12" class="pt-0">
                <v-radio-group v-model="payment" @change="choosePayment()" class="pt-0">
                    <v-radio
                        v-for="(method, index) in payment_methods"
                        :key="index"
                        :label="method.show"
                        :value="method.code"
                    ></v-radio>
                </v-radio-group>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn 
                        :disabled="!valid"
                        block
                        depressed
                        large
                        color="primary"
                        :loading="loading"
                        @click="paymentProcess(paymentData)"
                    >
                        Process to payment
                    </v-btn>

                    <v-snackbar
                        v-model="snackbar"
                    >
                        {{ text }}

                        <template v-slot:action="{ attrs }">
                            <v-btn
                            color="primary"
                            text
                            v-bind="attrs"
                            @click="snackbar = false"
                            >
                            Close
                            </v-btn>
                        </template>
                        </v-snackbar>

                <v-dialog
                eager
                max-width="500"
                v-model="dialog"
                >
                    <v-card>
                        <iframe 
                            v-if="iframeReady"
                            :src="iframe"
                            frameborder="0"
                        ></iframe>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </div>
</template>
<script>
const axios = require('axios').default;

export default {
    name: 'PaymentForm',
    props: {
        price: Number,
        paymentData: Object,
        valid:Boolean,
        coba:Object
    },
    data() {
        return {
            iframeReady: false,
            loading: false,
            dialog: false,
            snackbar: false,
            text: '',
            payment_methods: [
                {
                    show: 'Credit card',
                    code: ["CREDIT_CARD"]
                },
                {
                    show: 'Bank transfer',
                    code: [
                        "VIRTUAL_ACCOUNT_BCA",
                        "VIRTUAL_ACCOUNT_BANK_MANDIRI",
                        "VIRTUAL_ACCOUNT_BANK_SYARIAH_MANDIRI",
                        "VIRTUAL_ACCOUNT_BRI",
                        "VIRTUAL_ACCOUNT_BNI",
                        "VIRTUAL_ACCOUNT_DOKU",
                        "VIRTUAL_ACCOUNT_PERMATA",
                        "VIRTUAL_ACCOUNT_CIMB",
                        "VIRTUAL_ACCOUNT_DANAMON"
                    ]
                },
                {
                    show: 'Qris',
                    code: ["QRIS"]
                },
                {
                    code: '[else]',
                    show: 'Else'
                },
            ],
            iframe:'',
            payload: this.paymentData,
            payment:[]
        }
    },
    computed: {
        currency() {
            return new Intl.NumberFormat('en-US').format(this.price);
        }
    },
    // mounted() {
    //     console.log(this.coba.validate())
    // },
    methods: {
        choosePayment() {
            this.payload.paymentMethods = this.payment
            if (this.payment[0] === 'VIRTUAL_ACCOUNT_BCA') {
                this.payload.channel = 'VIRTUAL_ACCOUNT'
            } else {
                this.payload.channel = this.payment[0]
            }
        },
        async paymentProcess(paymentData) {
        // paymentProcess() {
            try {
                this.coba.validate()   
            } catch (error) {
                this.text = 'Please fullfil the details'
                this.snackbar = true
            }
            if (this.coba.validate() != false && this.payload.paymentMethods != "") { 
                this.loading = true
                await axios.post('https://api.oneworldbali.com/payment', paymentData).then(response => {
                    console.log(response);
                    if (response.status == 200) {
                        this.iframe = response.data
                        this.dialog = true
                        this.iframeReady = true
                    }else if (response.status == 201) {
                        // this.$router.push('/'+response.data.invoice)                    
                    }
                }).catch(error => {
                    console.log(error.response)
                });
                this.loading= false  
            } else if (this.coba.validate() != false && this.payload.paymentMethods == "") {
                this.text = 'You do not choose yet the payment method'
                this.snackbar = true
            }
        }
    }
}
</script>

<style scoped>
iframe {
    height: 650px;
}
.sticky-top {
    position: fixed;
}
.pb-0{
    padding-bottom: 0!important;
}
.pt-0{
    padding-top: 0!important;
}
</style>
